/** @jsx jsx */

import { jsx, css } from '@emotion/core';
import promoBanner from 'assets/landing_page/promo-banner.json';
import { useState } from 'react';

const bannerStyle = css`
  background-color: #4a9a5e;
  color: white;
  padding: 25px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;

  .link {
    color: white;
    text-decoration: underline;
  }

  .dismiss {
    cursor: pointer;
    float: right;
  }
`;

const PromoBanner = () => {
  const [showBanner, setShowBanner] = useState(true);
  const isMobile = window.innerWidth <= 1000;

  if (promoBanner.startDate && promoBanner.endDate) {
    const startDate = new Date(promoBanner.startDate);
    const endDate = new Date(promoBanner.endDate);
    const now = new Date();
    if (now < startDate || now > endDate) {
      return null;
    }
  }

  if (!showBanner || isMobile) {
    return null;
  }

  return (
    <div css={bannerStyle}>
      <span className="text">
        {promoBanner.message}
        <a
          href={promoBanner.link}
          rel="noopener noreferrer"
          className="link"
          target="_blank"
        >
          {promoBanner.cta}
        </a>
      </span>
      <span onClick={() => setShowBanner(false)} className="dismiss">
        x
      </span>
    </div>
  );
};
export default PromoBanner;
